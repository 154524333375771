@import "~bootstrap/scss/bootstrap.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./style/variables.scss";
@import "./style/mixins.scss";

html,
body {
  min-height: 100vh;
  scroll-behavior: smooth;
}

.fade-enter {
  opacity: 0;
  z-index: 1;

}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

html {
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0 auto;
  body {
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;

    margin: 0 auto;
    padding: 0;
    font-family: 'Roboto Condensed', sans-serif;
    .App {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0;
      .image-container {
        position: relative;
        @include desktop-only {
          bottom: 236px;
        }
        img {
          width: 100%;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
        }
      }
      
      .Header {
        margin: 0 auto;

        .Header {
         max-width: 80%;
         .Menu {
          align-items: center;
          align-content: center;
          font-size: 1.2rem;
          a {
            color: #000;
            text-decoration: underline transparent;
            text-underline-offset: 0.2em;
            text-transform: uppercase;
            line-height: 3rem;
            text-align: center;
            @include transition(text-decoration-color 400ms);

            &:hover {
              text-decoration-color: initial;
              
            }
          }
          a {
            &.Logo {
              @include maximize;
              .Logo-image {
                max-width: 70%;
                background-color: rgba(200, 200, 200, 0.5);
                border-radius: 7%;
                @include mobile-only {
                  background-color: rgba(200, 200, 200, 0.5);
                }
              }
              @include mobile-only {
                max-width: 35%;
              }
              @include bp-sm-mobile-xs {
                max-width: 40%;
                text-align: center;
              }
            } 
          }
          .Home {
            @include mobile-only {
              text-align: center;
            }
            @include bp-sm-mobile-xs {
              text-align: start;
            }
          }

          .Menu-item {
            @include bp-sm-mobile-xs {
              display: none;
            }
          }
          .Menu-item-mobile-closed {
            width: 100%;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
            transition: opacity .5s, max-height .8s, visibility .8s ease-in-out;
          }
          .Menu-item-mobile {
            max-height: 3rem;
            visibility: visible;
            opacity: 1;
            width: 100%;
            transition: opacity 0.5s, max-height 0.8s, visibility .8s ease-in-out;
            &:active {
              text-decoration: underline;
            }
          }
          @include mobile-only {
            justify-content: space-between;
            height: 100%;
          }
          @include bp-tablet-lg {
            padding: 0
          }
          &.row {
            &.mob {
              max-width: 80%;
              margin: 0 auto;

              a {
                &.Home {
                  font-weight: 400;
                  font-size: 1.5rem;
                }
              }
            }
          }
        }


        }
    
        
        @include mobile-only {
          min-height: 4.5rem;
          padding: 0;
        }
        &.row {
          position: absolute;
          top: 0;
          width: 100%;
          margin: 0 auto;
          
        }
      }
      .Content {
        @include desktop-only {
          position: relative;
          bottom: 200px;
        }
      }
      
    }
    .image-gallery {
      .image-gallery-right-nav {
        right: -42px;
      }
      .image-gallery-left-nav {
        left: -42px;
      }
      button {
        
        @include mobile-only {
          display: none;
        }
      }
      .image-gallery-swipe {
        .image-gallery-image {
          @include bp-sm-mobile-xs {
            padding: 0;
          }
        }
      }
    }
    .Main {
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 2rem;
      .Mobile-card-container {
        min-height: 10rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 8%;
        padding-top: 1rem;
        .mobile {
          &.row {
            .mobile {
              &.title {
                text-transform: uppercase;
                text-align: center;
                @include bp-sm-mobile-xs {
                  font-size: 1.5rem;
                  text-transform: uppercase;
                }
              }
              .title-hidden {
                display: none;
              }
              .card-open {
                text-align: justify;
                margin: 0;
              }

              .card-closed {
                display: none;
              }
            }
            &.arrow {
              justify-content: flex-end;
            }
          }
        }
      }

      .Home-image {
        outline: 0 0 2px $light-blue;

        @include transition(outline, 1s);
        &:hover {
          box-shadow: inset 0 0 0 4px $light-blue;
        }
      }

      @include bp-sm-mobile-xs {
        max-width: 100%;
      }
    }
    .Main-page {
      margin-bottom: 1rem;
      padding-top: 3rem;
      @include mobile-only {
        padding-top: 3rem;
      }
      h3 {
        font-weight: bold;
        margin-bottom: 1rem;
        text-align: center;
      }
      .Map {
        align-self: center;
        display: flex;
        justify-content: center;
        min-height: 9rem;
        iframe {
          min-width: 100%;
          min-height: 18rem;
          border-radius: 3%;
        }

        .loader {
          justify-content: center;
        }
      }

      form {
        text-align: center;
        label {
          width: 80%;
          text-align: left;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 0.15rem;
        }
        textarea {
          width: 80%;
        }
        input {
          width: 80%;
          margin: 0 auto;
          margin-bottom: 1rem;
          &.submit-button {
            border-radius: 8px;
            background-color: #000;
            color: #fff;
            font-weight: bold;
            width: 60%;
          }
        }
        .submit-button {
          font-size: 1.5rem;
          text-transform: uppercase;
        }
      }
      .Form-submitted {
        text-align: center;
      }
      .Back-to-form {
        background-color: #FFFFFF;
        border: 1px solid #222222;
        border-radius: 8px;
        box-sizing: border-box;
        color: #222222;
        cursor: pointer;
        display: inline-block;
        font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        outline: none;
        padding: 13px 23px;
        position: relative;
        text-align: center;
        text-decoration: none;
        touch-action: manipulation;
        transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
        user-select: none;
        -webkit-user-select: none;
        width: auto;
        &:hover,
        &:focus {
          border-color: rgba(0, 0, 0, 0.15);
          box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }

    .footer-container {
        position: absolute; 
        bottom: 0; 
        width: 100%;
        margin: 0 auto;

      .Footer {
        min-height: 2rem;
        background-color: #648591;
        display: flex;
        justify-content: center;
  
        .Copyright {
          align-self: center;
        }
      }

    }
    
  }
}

.Image {
  max-height: 100%;
}

.Align-self-flex-end {
  align-self: flex-end;
}

.Row-reverse {
  flex-direction: row-reverse;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  @include mobile-only {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
  .whatsapp-icon {
    margin-top: 16px;
    @include mobile-only {
      margin-top: 10px;
    }
  }
}
